import apiAddress from './address'

export default {
  async importLead (data) {
    await fetch('/send.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        name: data.name,
        email: data.email,
        phone: data.phone,
        note: data.note
      })
    })
    return apiAddress().post('/lead/import-lead', data, {
    }).catch(() => alert('Ошибка соединения. Повторите попытку или свяжитесь с нами по телефону.'))
  }
}
